import React, { Component } from 'react';
import logo from '../logo.svg';
import "./projects.css";

export default class Projects extends Component {

    render() {
        return (
            <div className="projectsDiv">
                <div className="titleDiv">
                    <a name="projectsTarget">
                        <h3 id="projectsHeader">
                            Projects
                        </h3>
                        <div className='projectDisplayContainerDiv'>
                        <div className="projectDisplayDiv">
                            <div className="projectInfoDiv">
                                <h4 className="projectTitle">CCs Loving Care, LLC - Senior Home Care</h4>
                                <h5>Caretaker Scheduling App</h5>

                                <label htmlFor='projectDescriptionCCs' className="projectLabel">Description</label>
                                <p id="projectDescriptionCCs">
                                    A single-page web application built with React.js that I designed, developed, and deployed on commission for a local senior caretaking company.<br/><br />
                                    App is used daily by administration and caretakers to check schedules, adjust times worked, trade shifts, calculate total hours per week, and calculate client costs. <br /><br />
                                </p>
                                <label className="builtWithLabel" htmlFor='builtWithListCCs'>Built With:</label>
                                <ul id="builtWithListCCs" className='builtWithList'>
                                <li><img src={logo} className="App-logo" alt="React logo" /></li>
                                <li><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Firebase_Logo.svg/1280px-Firebase_Logo.svg.png" alt="Firebase"></img></li>
                                </ul>
                                
                            </div>

                            <div className="projectPhotoDiv">
                            <img src="https://live.staticflickr.com/65535/52016445218_8256e207e3_h.jpg" 
                            alt="CCs caretaking service Project tile" id="CCsTile"/>
                            </div>
                        
                        </div>



                        <div className="projectDisplayDiv">
                            <div className="projectInfoDiv">
                                <h4 className="projectTitle">Sports Statistical Query Application</h4>

                                <label htmlFor='projectDescriptionSSQA' className="projectLabel">Description</label>
                                <p id="projectDescriptionSSQA">
                                    Scrapes basketball statistics and stores information in a MySQL relational database.<br /><br />
                                    Allows user to quickly lookup team stats with important splits (last 10 games, home/away, etc.) displayed and color coded in relation to player's season average in order to visually spot trending players.<br /><br />
                                    Allows user to build compound queries for different players statistical thresholds and displays resulting games where each player meets the statistical criteria in the same game.<br /><br />
                                    Backend is built as a REST API using Java Spring Boot, web scraping done econimically using Java, specific splits calculated and stored in MySQL database.<br/><br/>
                                    Frontend is built with React.js and uses Axios to make requests.
                                </p>
                                <label className="builtWithLabel" htmlFor='builtWithListSSQA'>Built With:</label>
                                <ul id="builtWithListSSQA" className='builtWithList'>
                                <li><img src={logo} className="App-logo" alt="React logo" /></li>
                                <li><img src="https://www.mysql.com/common/logos/powered-by-mysql-167x86-wob.png" alt="MySQL" /></li>
                                <li><img src="https://1000logos.net/wp-content/uploads/2020/09/Java-Logo-500x313.png" alt="Java" /></li>
                                </ul>
                                
                            </div>

                            <div className="projectPhotoDiv">
                            <img src="https://live.staticflickr.com/65535/52015415622_6179a967ec_b.jpg" 
                            alt="Sports Statistical Query Application Project tile" id="SSQATile"/>
                            </div>
                        
                        </div>





                        <div className="projectDisplayDiv">




                            <div className="projectInfoDiv">
                                <h4 className="projectTitle">Jeopardy Trivia Trainer</h4>
                                <label htmlFor='projectDescriptionJeopardy' className="projectLabel">Description</label>
                                <p id="projectDescriptionJeopardy">
                                    A full-stack application that consists of a web scraper for clues and an interactive clue trainer. The web scraping portion is written in Java and uses jsoup to 
                                    scrape clue information. The data is stored in a database using MySQL. The server-side portion of the clue trainer is written in Node.js and 
                                    the front-end is built using React. <br /><br />
                                    I like to use it to quiz myself on random questions, specific categories I struggle with, or even just the toughest questions.
                                </p>
                                <label className="builtWithLabel" htmlFor="builtWithListJeopardy">Built With:</label>
                                <ul id="builtWithListJeopardy" className='builtWithList'>
                                <li><img src={logo} className="App-logo" alt="React logo" /></li>
                                <li><img src="https://nodejs.org/static/images/logos/nodejs-new-pantone-black.svg" alt="Node.js" /></li>
                                <li><img src="https://www.mysql.com/common/logos/powered-by-mysql-167x86-wob.png" alt="MySQL" /></li>
                                <li><img src="https://1000logos.net/wp-content/uploads/2020/09/Java-Logo-500x313.png" alt="Java" /></li>
                                </ul>
                            </div>

                            <div className="projectPhotoDiv">
                            <img src="https://live.staticflickr.com/65535/51283834556_6d66eff69d_b.jpg" 
                            alt="Dont Lose on Jeopardy! Project tile" id="DLoJTile"/>
                            </div>

                        </div>
                            
                            
                        </div>
                        
                        
                    </a>
                </div>
            </div>
        )
    }

} 