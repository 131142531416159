import React, { Component } from 'react';
import './about.css';

export default class About extends Component {

    render() {
        return (
            <div className="aboutDiv">
                <div className="titleDiv">
                    <a name="aboutTarget">
                        <h3 id="aboutHeader">
                            About
                        </h3>

                        <p id="aboutMe">
                            I graduated in December 2020 from the University of Arizona with a degree in Computer Science. <br /><br />

                            In 2021 I designed, developed, and deployed a scheduling website for CC's Loving Care senior caretaking service. <br />
                            The website is used daily by around 20 caretakers to track their schedules and hours worked. <br /><br />

                            I have experience developing with JavaScript, React, and Java. <br /> <br />

                            I am excited to develop and hone my skills as a Software Developer. <br/><br/>
                            
                            I enjoy learning new technologies and languages,
                            and I am always excited to discover new ways to solve problems. <br /> <br />
                          
                            I am a big football and basketball fan, and I also really enjoy motorsports, specifically Formula 1, and Indycar. <br /><br />
                            I also <strong>love</strong> Jeopardy! and I hope to compete on the show one day!
                            
                            </p>
                    </a>
                </div>
            </div>
        )
    }

}