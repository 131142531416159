import react, { Component } from 'react';
import "./contact.css";

export default class Contact extends Component {
    render() {
        return (
            <div className="contactDiv">
                <div className="titleDiv">
                    <a name="contactTarget">
                        <h3 id="contactHeader">
                            Contact
                        </h3>

                        <p id="contactMe">
                            Email me at: paulrichdev@gmail.com
                            
                            </p>
                    </a>
                </div>
            </div>
        )
    }
}