import logo from './logo.svg';
import NavBar from './components/NavBar'
import Introduction from './components/Introduction';
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';
import './App.css';

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className='AppBody'>
        <Introduction />
        <Projects />
        <About />
        <Contact />
        
      </div>
      
    </div>
  );
}

export default App;
