import React from 'react'
import './introduction.css'

export default function Introduction() {
  return (
    <div className="IntroductionDiv">
      <h1>Hi, I'm Paul Rich</h1>
      <h2>I'm a Software Engineer</h2>
      <h3>Currently in Tucson, Arizona and looking to break into the professional software development field.</h3>
      <p id="introBlurb">
        I have experience with Java, JavaScript, React, MySQL, REST APIs, and I am always excited to learn about a new language or technology!  
      </p>
    </div>
  )
}
