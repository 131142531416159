import React, { Component } from 'react';
import "./navBar.css";

export default class NavBar extends Component {
    render() {
        return (
            <div className="navBar">
                <h1 id="myName">Paul Rich</h1>
                <ul className="PAC">

                    <li><div className="ProjectsNav">
                        <a href="#projectsTarget" className='navBarOption'>Projects</a>
                    </div></li>
                    <li><div className="AboutNav">
                    <a href="#aboutTarget" className='navBarOption'>About</a>
                    </div></li>
                    <li><div className="ContactNav">
                    <a href="#contactTarget" className='navBarOption'>Contact</a>
                    </div></li>
                    <li><div className="ResumeNav">
                    <a href="#resumeTarget" className='navBarOption'>Resume</a>
                    </div></li>
                    
                </ul>
                    
                <ul className="Socials">
                <li><div className="Github">
                        <a href="https://github.com/Scoobi-Wan" target="_blank"
                        className="profile-link"><img src="https://github.githubassets.com/images/modules/logos_page/Octocat.png"/></a>
                    </div></li>
                    <li><div className="LinkedIn">
                        <a href="https://www.linkedin.com/in/pauldrich2/" target="_blank"
                        className="profile-link"><img src="https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Bug.svg.original.svg"/></a>
                    </div></li>
                    
                </ul>


            </div>
        )
    }
}
